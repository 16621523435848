export default {
    "address": "0x913B98B271889D3fB4D375C181FC2E58f17EC6C5",
    "abi": [{
        "inputs": [{"internalType": "address", "name": "initialOwner", "type": "address"}],
        "stateMutability": "nonpayable",
        "type": "constructor"
    }, {
        "inputs": [{"internalType": "address", "name": "sender", "type": "address"}, {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }, {"internalType": "address", "name": "owner", "type": "address"}],
        "name": "ERC721IncorrectOwner",
        "type": "error"
    }, {
        "inputs": [{"internalType": "address", "name": "operator", "type": "address"}, {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }], "name": "ERC721InsufficientApproval", "type": "error"
    }, {
        "inputs": [{"internalType": "address", "name": "approver", "type": "address"}],
        "name": "ERC721InvalidApprover",
        "type": "error"
    }, {
        "inputs": [{"internalType": "address", "name": "operator", "type": "address"}],
        "name": "ERC721InvalidOperator",
        "type": "error"
    }, {
        "inputs": [{"internalType": "address", "name": "owner", "type": "address"}],
        "name": "ERC721InvalidOwner",
        "type": "error"
    }, {
        "inputs": [{"internalType": "address", "name": "receiver", "type": "address"}],
        "name": "ERC721InvalidReceiver",
        "type": "error"
    }, {
        "inputs": [{"internalType": "address", "name": "sender", "type": "address"}],
        "name": "ERC721InvalidSender",
        "type": "error"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "ERC721NonexistentToken",
        "type": "error"
    }, {"inputs": [], "name": "InsufficientBorrowReceived", "type": "error"}, {
        "inputs": [],
        "name": "PairNotExists",
        "type": "error"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "approved",
            "type": "address"
        }, {"indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "Approval",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "operator",
            "type": "address"
        }, {"indexed": false, "internalType": "bool", "name": "approved", "type": "bool"}],
        "name": "ApprovalForAll",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "sender", "type": "address"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }],
        "name": "Burn",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "sender", "type": "address"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }],
        "name": "Mint",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "user", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
        }],
        "name": "OwnershipTransferred",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "from", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "Transfer",
        "type": "event"
    }, {
        "inputs": [],
        "name": "ONE_MILLION",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "to", "type": "address"}, {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }], "name": "approve", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "owner", "type": "address"}],
        "name": "balanceOf",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "positionId", "type": "uint256"}],
        "name": "checkLiquidate",
        "outputs": [{"internalType": "bool", "name": "liquidated", "type": "bool"}, {
            "internalType": "uint256",
            "name": "releaseAmount",
            "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "getApproved",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "positionId", "type": "uint256"}],
        "name": "getPosition",
        "outputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "marginForOne", "type": "bool"}, {
                "internalType": "uint256",
                "name": "marginAmount",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "marginTotal", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "borrowAmount",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "rateCumulativeLast", "type": "uint256"}],
            "internalType": "struct MarginPosition",
            "name": "_position",
            "type": "tuple"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}, {
            "internalType": "bool",
            "name": "marginForOne",
            "type": "bool"
        }, {"internalType": "address", "name": "owner", "type": "address"}],
        "name": "getPositionId",
        "outputs": [{"internalType": "uint256", "name": "_positionId", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "hook",
        "outputs": [{"internalType": "contract IMarginHookManager", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
            "internalType": "address",
            "name": "operator",
            "type": "address"
        }],
        "name": "isApprovedForAll",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "positionId", "type": "uint256"}],
        "name": "liquidate",
        "outputs": [{"internalType": "uint256", "name": "profit", "type": "uint256"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "marginForOne", "type": "bool"}, {
                "internalType": "uint24",
                "name": "leverage",
                "type": "uint24"
            }, {"internalType": "uint256", "name": "marginAmount", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "marginTotal",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "borrowAmount", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "borrowMinAmount",
                "type": "uint256"
            }, {"internalType": "address", "name": "recipient", "type": "address"}, {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            }], "internalType": "struct MarginParams", "name": "params", "type": "tuple"
        }],
        "name": "margin",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [],
        "name": "name",
        "outputs": [{"internalType": "string", "name": "", "type": "string"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "owner",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "ownerOf",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "positionId", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "repayAmount",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
        "name": "repay",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}, {
            "internalType": "bytes",
            "name": "data",
            "type": "bytes"
        }], "name": "safeTransferFrom", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "operator", "type": "address"}, {
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
        }], "name": "setApprovalForAll", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "_hook", "type": "address"}],
        "name": "setHook",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "bytes4", "name": "interfaceId", "type": "bytes4"}],
        "name": "supportsInterface",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "symbol",
        "outputs": [{"internalType": "string", "name": "", "type": "string"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "tokenURI",
        "outputs": [{"internalType": "string", "name": "", "type": "string"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "newOwner", "type": "address"}],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "amount", "type": "uint256"}],
        "name": "withdrawFee",
        "outputs": [{"internalType": "bool", "name": "success", "type": "bool"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {"stateMutability": "payable", "type": "receive"}]
}